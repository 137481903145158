import React from "react"
import Layout from "../components/layout"
import Nav from "../components/nav"
import SEO from "../components/seo"
import Partners from "../components/partners"
import "./booking.css"

const lang = {
  en: {
    header: "Booking",
    content1: "Contact one of our partners for booking and prices",
  },
  sv: {
    header: "Bokning",
    content1: "Kontakta någon av våra partners för boking och priser",
  },
}

const Booking = () => {
  return (
    <Layout>
      <SEO
        title="Booking"
        description="Book your unique scuba dive with one of our partners"
      />
      <Nav />
      <div className="booking__header"></div>
      <div className="booking__section">
        <div className="booking__box">
          <h1>{lang.en.header}</h1>
          <div className="booking__content">
            <p>{lang.en.content1}</p>
          </div>
          <Partners />
        </div>
      </div>
    </Layout>
  )
}

export default Booking
