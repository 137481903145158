import React from "react"
import captainBalticLogo from "../../images/captain-baltic.png"
import dykcharterLogo from "../../images/dykcharter.png"
import sthlmWreckguideLogo from "../../images/sthlm-wreckguide.png"
import "./partners.css"

const Partners = () => (
  <div className="partner__wrapper">
    <a
      className="partner__wrapper--link"
      href="https://vrakdykarpensionatet.se/"
    >
      <p className="partner__name">Captain Baltic</p>
      <p className="partner__details">
        Diving and everything a diver needs. Wrecks, marine life, accomondation,
        food, boat trips, courses, dive shop, equipment, gasfilling
        nitrox/trimix/oxygen/sofnolime, lots of history and a unique experience
        in the Baltic Sea environment!
      </p>
      <img
        src={captainBalticLogo}
        alt="captain baltic logo"
        className="partner__logo"
      />
      <a
        className="partner__link"
        href="mailto:info@captainbaltic.se"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@captainbaltic.se
      </a>
    </a>
    <a
      className="partner__wrapper--link"
      href="https://www.dykcharter.se/sv-SE"
    >
      <p className="partner__name">Dykcharter</p>
      <p className="partner__details">
        Day tours - Pakages - Dalarö Dive park tours throughout the whole year
      </p>
      <img
        src={dykcharterLogo}
        alt="dykcharter logo"
        className="partner__logo"
      />
      <a
        className="partner__link"
        href="mailto:info@dykcharter.se"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@dykcharter.se
      </a>
    </a>
    <a
      className="partner__wrapper--link"
      href="https://www.facebook.com/Dykbaten"
    >
      <p className="partner__name">Stockholms Wreckguide</p>
      <p className="partner__details">
        Your guide beneath the surface. Diving in Dalarö Diving park. Night
        tours, day tours and weekend arrangements.
      </p>
      <img
        src={sthlmWreckguideLogo}
        alt="dykbåten logo"
        className="partner__logo"
      />
      <a
        className="partner__link"
        href="mailto:info@dykbaten.se"
        target="_blank"
        rel="noopener noreferrer"
      >
        info@dykbaten.se
      </a>
    </a>
  </div>
)

export default Partners
